.slick-slider{
    width: 1080px;
    height: calc(1920px - 300px);
  }
  .slick-slide{
    height: 100% !important;
  }
  
  .slick-track{
    height: 1920px;
  }